import { Group, Skeleton, Stack } from '@mantine/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { TenantGet } from '../../../helpers/fetchApis/Properties';
import DashboardCard from '../../components/CommonComponents/DashboardCard';
import DashboardDocumentsCard from '../../components/CommonComponents/DashboardDocumentsCard';
import DashboardMessagesCard from '../../components/CommonComponents/DashboardMessagesCard';
import DashboardPropertyCard from '../../components/CommonComponents/DashboardPropretyCard';
import FirstStepSectionCard from '../../components/CommonComponents/FirstStepSectionCard';
import FirstStepSectionCardCopy from '../../components/CommonComponents/FirstStepSectionCardCopy';

class Dashboard extends Component {

  state={
    allDashboardCardsAvailable: true
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {profile, tenant, rentalUnit, property} = this.props;
    window.history.pushState({}, undefined, "/dashboard");
    this.setState({
      allDashboardCardsAvailable: (Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden && !property?.isDocumentSectionHidden && !property?.isMessageSectionHidden)
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.property !== this.props.property){
      this.setState({
        allDashboardCardsAvailable: (Object.keys(this.props.property).length > 0 && !this.props.property?.isConsumptionSectionHidden && !this.props.property?.isDocumentSectionHidden && !this.props.property?.isMessageSectionHidden)
      });
    }
  }

  render() {
    const {allDashboardCardsAvailable} = this.state;
    const {skeletonLoader, isMobile, isTablet, tenant, rentalUnit, property, rent} = this.props;
    console.log('componentDidMount: ', tenant, rentalUnit, property);
    return (
      <>
        <Stack className="container" id="respond-dashboard-page">
          {skeletonLoader ? 
          <Skeleton style={{height: '236px'}} />
          :
          <Group grow>
            <img style={{borderRadius: '4px', height: '237px', objectFit: 'cover'}} src={property && property.pictureLocation ? property.pictureLocation : './houseDefault.png'}/>
          </Group>}
          <Stack spacing={10}>
            {property && property.propertyAddress && property.propertyAddress.streetNo && <span className='dashboard-property-name' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{property && property.propertyAddress.streetNo ? property.propertyAddress.streetNo : property.name}</Skeleton></span>}
            {property && property.propertyAddress && (property.propertyAddress.zipCode || property.propertyAddress.city) && <span className='dashboard-property-address' style={{width: 'fit-content'}}><Skeleton visible={skeletonLoader}>{`${property && property.propertyAddress.zipCode} ${property && property.propertyAddress.city}`}</Skeleton></span>}
          </Stack>
          {rent && rent.length > 0 && <div>
            <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
              <DashboardPropertyCard isMobile={isMobile} isTablet={isTablet} tenant={tenant} rentalUnit={rentalUnit} property={property} rent={rent} />
            </Skeleton>
          </div>}
          
          {/* <FirstStepSectionCard/> */}
          <Group //grow={!isMobile}
          >
            {/* <div style={{width: isMobile && '100%'}}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <Group grow>
                  <DashboardCard
                  heading={'Dokumente'}/>
                </Group>
              </Skeleton>
            </div> */}
            <div style={{width: '100%'}}>
              <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                <Group grow={allDashboardCardsAvailable}
                noWrap={!allDashboardCardsAvailable}
                >
                  {Object.keys(property).length > 0 && !property?.isConsumptionSectionHidden &&
                  <DashboardCard
                  heading={'Verbräuche'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isDocumentSectionHidden && 
                  <DashboardDocumentsCard
                  heading={'Dokumente'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}
                  />}
                  {!property?.isMessageSectionHidden && 
                  <DashboardMessagesCard
                  heading={'Nachrichten'}
                  allDashboardCardsAvailable={allDashboardCardsAvailable}/>}
                </Group>
              </Skeleton>
            </div>
          </Group>
        </Stack>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      profile: state.generalReducer.profileData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      account: state.generalReducer.accountData,
      skeletonLoader: state.generalReducer.skeletonLoader,
      tenant: state.generalReducer.tenantData,
      rentalUnit: state.generalReducer.rentalUnitData,
      property: state.generalReducer.propertyData,
      rent: state.generalReducer.rentData
  };
};

export default connect(mapStateToProps)(Dashboard);
