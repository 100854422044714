import { Group, Progress, Stack, Table, Tooltip } from '@mantine/core';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { tenantConsumptionGet } from '../../../helpers/fetchApis/MeterConsumptions';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import { useState } from 'react';
import EmptyState from './EmptyState';
import { formatNumber } from '../../../helpers/Uitls';

const DashboardCard = (props) => {
    let navigate= useNavigate();
    const [tenantConsumptionHeating, setTenantConsumptionHeating] = useState(null);
    const [tenantConsumptionWarmwater, setTenantConsumptionWarmwater] = useState(null);
    const [tenantConsumptionTotal, setTenantConsumptionTotal] = useState(null);
    
    const getTenantConsumption=()=>{
        const {profile, set_skeleton_loader, property} = props;
        
        (property.warmWaterConsumptionAllocationKey !== null && property.heatingConsumptionAllocationKey !== null) && tenantConsumptionGet('HeatingTotal', dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), profile.id, set_skeleton_loader, res=> {
            console.log('tenantConsumptionGet: ', res);
            setTenantConsumptionTotal(res);
        });
        property.heatingConsumptionAllocationKey !== null && tenantConsumptionGet('RoomHeating', dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), profile.id, set_skeleton_loader, res=> {
            console.log('tenantConsumptionGet: ', res);
            setTenantConsumptionHeating(res);
            if(property.warmWaterConsumptionAllocationKey === null && property.heatingConsumptionAllocationKey === null){
                setTenantConsumptionTotal(res);
            }
        });
        property.warmWaterConsumptionAllocationKey !== null && tenantConsumptionGet('WarmWater', dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), profile.id, set_skeleton_loader, res=> {
            console.log('tenantConsumptionGet: ', res);
            setTenantConsumptionWarmwater(res);
        });
    }
    useEffect(() => {
        getTenantConsumption();
    }, []);
  return (
    <Stack className='dashboard-general-card' spacing={'lg'}>
        <>
            <Group position='apart'>
                <Group noWrap align='flex-end'>
                    <span className='dashboard-general-card-heading'>{props.heading}</span>
                    <span style={{fontSize: '12px', fontWeight: '500', color: '#727293'}}>{dayjs().subtract(1, 'month').endOf('month').format('MMM YY')}</span>
                </Group>
                <Group className='dashboard-general-card-all' onClick={()=> navigate('/consumption')} align={'flex-end'}>
                    <span style={{cursor: 'pointer'}}>Alle</span>
                </Group>
            </Group>
            {(props.property.warmWaterConsumptionAllocationKey !== null || props.property.heatingConsumptionAllocationKey !== null) ?
            <Table verticalSpacing="sm">
                <tbody>
                    <tr>
                        <td width={'33%'} className='dashboard-consumption-card-name remove-border'>
                            Heizung
                        </td>
                        <td width={'33%'} style={{textAlign: 'right'}} className='remove-border'>
                            <span style={{whiteSpace: 'nowrap'}}><span className='dashboard-consumption-card-value'>{tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH ? formatNumber(tenantConsumptionHeating.consumptionKWH, false) : '0,00'}</span> <span className='dashboard-consumption-card-value-unit'>kWh</span></span>    
                        </td>
                        <td width={'33%'} className='dashboard-consumption-card-percentage remove-border'>
                            <Group position='right'>
                                {tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH && tenantConsumptionHeating.lastYearConsumptionKWH ?
                                <Tooltip
                                label={<span style={{fontSize: '12px', fontWeight: '500'}}>{`${tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH && tenantConsumptionHeating.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionHeating.consumptionKWH - tenantConsumptionHeating.lastYearConsumptionKWH) / tenantConsumptionHeating.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}% ${tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH > tenantConsumptionHeating.lastYearConsumptionKWH ? 'mehr als im Vorjahr' : 'weniger als im Vorjahr'}`}</span>}
                                color="#e3e3fc"
                                style={{color: '#000000'}}
                                position="top-end"
                                withArrow
                                >
                                    <div style={{background: tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH > tenantConsumptionHeating.lastYearConsumptionKWH ? '#FFCBCB' : '#E8F4EA', display: 'flex', alignItems: 'center', height: '19px', padding: '0px 8px', justifyContent: 'space-between', borderRadius: '10px', width: 'auto', minWidth: '59px'}}>
                                        <span style={{fontSize: '10px', fontWeight: '600', color: tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH > tenantConsumptionHeating.lastYearConsumptionKWH ? '#E8193C' : '#059266', marginRight: '5px'}}>{`${tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH > tenantConsumptionHeating.lastYearConsumptionKWH ? '+' : '-'}${tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH && tenantConsumptionHeating.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionHeating.consumptionKWH - tenantConsumptionHeating.lastYearConsumptionKWH) / tenantConsumptionHeating.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}%`}</span>
                                        <img src={tenantConsumptionHeating && tenantConsumptionHeating.consumptionKWH > tenantConsumptionHeating.lastYearConsumptionKWH ? HighConsumption : LowConsumption}/>
                                    </div>
                                </Tooltip>
                                :
                                '-'}
                            </Group>
                        </td>
                    </tr>
                    <tr>
                        <td width={'33%'} className={`dashboard-consumption-card-name remove-border ${props.property.warmWaterConsumptionAllocationKey === null && 'disable-text'}`}>
                            Warmwasser
                        </td>
                        <td width={'33%'} style={{textAlign: 'right'}} className={`remove-border ${props.property.warmWaterConsumptionAllocationKey === null && 'disable-text'}`}>
                            <span style={{whiteSpace: 'nowrap'}}><span className='dashboard-consumption-card-value'>{tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH ? formatNumber(tenantConsumptionWarmwater.consumptionKWH, false) : '0,00'}</span> <span className={`dashboard-consumption-card-value-unit ${props.property.warmWaterConsumptionAllocationKey === null && 'disable-text'}`}>kWh</span></span>
                        </td>
                        <td width={'33%'} className={`dashboard-consumption-card-percentage remove-border ${props.property.warmWaterConsumptionAllocationKey === null && 'disable-text'}`}>
                            <Group position='right'>
                                {tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH && tenantConsumptionWarmwater.lastYearConsumptionKWH ?
                                <Tooltip
                                label={<span style={{fontSize: '12px', fontWeight: '500'}}>{`${tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH && tenantConsumptionWarmwater.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionWarmwater.consumptionKWH - tenantConsumptionWarmwater.lastYearConsumptionKWH) / tenantConsumptionWarmwater.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}% ${tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH > tenantConsumptionWarmwater.lastYearConsumptionKWH ? 'mehr als im Vorjahr' : 'weniger als im Vorjahr'}`}</span>}
                                color="#e3e3fc"
                                style={{color: '#000000'}}
                                position="top-end"
                                withArrow
                                >
                                    <div style={{background: tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH > tenantConsumptionWarmwater.lastYearConsumptionKWH ? '#FFCBCB' : '#E8F4EA', display: 'flex', alignItems: 'center', height: '19px', padding: '0px 8px', justifyContent: 'space-between', borderRadius: '10px', width: 'auto', minWidth: '59px'}}>
                                        <span style={{fontSize: '10px', fontWeight: '600', color: tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH > tenantConsumptionWarmwater.lastYearConsumptionKWH ? '#E8193C' : '#059266', marginRight: '5px'}}>{`${tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH > tenantConsumptionWarmwater.lastYearConsumptionKWH ? '+' : '-'}${tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH && tenantConsumptionWarmwater.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionWarmwater.consumptionKWH - tenantConsumptionWarmwater.lastYearConsumptionKWH) / tenantConsumptionWarmwater.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}%`}</span>
                                        <img src={tenantConsumptionWarmwater && tenantConsumptionWarmwater.consumptionKWH > tenantConsumptionWarmwater.lastYearConsumptionKWH ? HighConsumption : LowConsumption}/>
                                    </div>
                                </Tooltip>
                                :
                                '-'}
                            </Group>
                        </td>
                    </tr>
                    <tr>
                        <td width={'33%'} className='dashboard-consumption-card-name remove-border'>
                            Gesamt
                        </td>
                        <td width={'33%'} style={{textAlign: 'right'}} className='remove-border'>
                            <span style={{whiteSpace: 'nowrap'}}><span className='dashboard-consumption-card-value'>{tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH ? formatNumber(tenantConsumptionTotal.consumptionKWH, false) : '0,00'}</span> <span className='dashboard-consumption-card-value-unit'>kWh</span></span>
                        </td>
                        <td width={'33%'} className='dashboard-consumption-card-percentage remove-border'>
                            <Group position='right'>
                                {tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH && tenantConsumptionTotal.lastYearConsumptionKWH ?
                                <Tooltip
                                label={<span style={{fontSize: '12px', fontWeight: '500'}}>{`${tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH && tenantConsumptionTotal.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionTotal.consumptionKWH - tenantConsumptionTotal.lastYearConsumptionKWH) / tenantConsumptionTotal.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}% ${tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH > tenantConsumptionTotal.lastYearConsumptionKWH ? 'mehr als im Vorjahr' : 'weniger als im Vorjahr'}`}</span>}
                                color="#e3e3fc"
                                style={{color: '#000000'}}
                                position="top-end"
                                withArrow
                                >
                                    <div style={{background: tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH > tenantConsumptionTotal.lastYearConsumptionKWH ? '#FFCBCB' : '#E8F4EA', display: 'flex', alignItems: 'center', height: '19px', padding: '0px 8px', justifyContent: 'space-between', borderRadius: '10px', minWidth: '59px',  width: 'auto'}}>
                                        <span style={{fontSize: '10px', fontWeight: '600', color: tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH > tenantConsumptionTotal.lastYearConsumptionKWH ? '#E8193C' : '#059266', marginRight: '5px'}}>{`${tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH > tenantConsumptionTotal.lastYearConsumptionKWH ? '+' : '-'}${tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH && tenantConsumptionTotal.lastYearConsumptionKWH ? `${Math.floor(Math.abs((tenantConsumptionTotal.consumptionKWH - tenantConsumptionTotal.lastYearConsumptionKWH) / tenantConsumptionTotal.consumptionKWH) * 100).toLocaleString("de-DE", {minimumFractionDigits: 0,maximumFractionDigits: 0})}` : '0'}%`}</span>
                                        <img src={tenantConsumptionTotal && tenantConsumptionTotal.consumptionKWH > tenantConsumptionTotal.lastYearConsumptionKWH ? HighConsumption : LowConsumption}/>
                                    </div>
                                </Tooltip>
                                :
                                '-'}
                            </Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
            :
            <Stack align={'center'} justify={'center'} style={{height: '100%'}} className='empty-state-container-dashboard'>
                <EmptyState
                icon={ConsumptionIcon}
                title='Verbräuche sind noch nicht verfügbar.'
                subText='Bitten Sie Ihren Vermieter die Einrichtung abzuschließen.'
                subTextClassName='empty-state-subtext-dashboard'
                />
            </Stack>
            }
        </>
    </Stack> 
  )
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      property: state.generalReducer.propertyData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      skeletonLoader: state.generalReducer.skeletonLoader
    };
  }
  
  
  const actions={
    set_loader,
    set_skeleton_loader
}
export default connect(mapStateToProps, actions)(DashboardCard);