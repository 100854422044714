import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Modal } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import RedCross from '../../../assests/svg/RedCross.svg';
import DatePickerIcon from '../../../assests/svg/DatePicker.svg';
import { EmailVerificationInstructionsPost, VerifyEmailPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';

const CustomInput = React.forwardRef((props, ref) => {
    return (
      <Group noWrap onClick={props.onClick} className='month-selector' spacing={10}>
        <img onClick={props.onClick} src={DatePickerIcon} width='16px'/>
        <span onClick={props.onClick} ref={ref}>         
          {props.value || props.placeholder}
        </span>
      </Group>
    );
});

const CustomDateRangePickerModal= (props) => {
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);

    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    useEffect(()=> {
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        console.log('props.tenant: ', new Date(props.tenant.moveInDate));
    }, [props.startDate, props.endDate]);

    return (
    <Modal
    opened={props.open}
    centered
    overlayOpacity={0.2}
    onClose={() => props.toggle(true)}
    title="Zeitraum">
            <Stack spacing={15}>
                <Group noWrap spacing={30} position='apart'>
                    <Stack style={{width: '100%'}} spacing={5}>
                        <label className='date-picker-labels'>Start</label>
                        <DatePicker
                        portalId="respond-dashboard-page"
                        locale="de"
                        selected={startDate}
                        onChange={(date) => {
                            // let {monthsSelectorData} = this.props;
                            // monthsSelectorData.map((item)=> {
                            //     if(item.label === 'Benutzerdefiniert'){
                            //         item.startDate= date;
                            //         return;
                            //     }
                            // })
                            // this.setState({monthsSelectorData, //startDate: dayjs(date).endOf('month').format('YYYY-MM-DD'), endDate: dayjs(others.endDate).endOf('month').format('YYYY-MM-DD')
                            // });
                            setStartDate(date < new Date(props.tenant.moveInDate) ? new Date(props.tenant.moveInDate) : date);
                        }}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomInput/>}
                        showMonthYearPicker
                        className='month-selector'
                        maxDate={new Date().setMonth(new Date().getMonth() - 1)}
                        minDate={new Date(props.tenant.moveInDate)}
                        />
                    </Stack>
                    {/* <span className='card-sub-text' style={{whiteSpace: 'nowrap'}}>bis</span> */}
                    <Stack style={{width: '100%'}} spacing={5}>
                        <label className='date-picker-labels'>Ende</label>
                        <DatePicker
                        popperClassName="react-datepicker-popper"
                        portalId="respond-dashboard-page"
                        locale="de"
                        selected={endDate}
                        onChange={(date) => {
                            // let {monthsSelectorData} = this.state;
                            // monthsSelectorData.map((item)=> {
                            //     if(item.label === 'Benutzerdefiniert'){
                            //         item.endDate= date;
                            //         return;
                            //     }
                            // })
                            // this.setState({monthsSelectorData, //startDate: dayjs(others.startDate).endOf('month').format('YYYY-MM-DD'), endDate: dayjs(date).endOf('month').format('YYYY-MM-DD')
                            // });
                            //console.log('onChange: ',new Date(dayjs(date).endOf('month')));
                            //setEndDate(new Date(dayjs(date).endOf('month')));
                            setEndDate(props.tenant.moveOutDate !== null ? (date > new Date(props.tenant.moveOutDate) ? new Date(props.tenant.moveOutDate) : new Date(dayjs(date).endOf('month'))) : new Date(dayjs(date).endOf('month')))
                        }}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomInput/>}
                        showMonthYearPicker
                        className='month-selector'
                        maxDate={props.tenant.moveOutDate ? (new Date(props.tenant.moveOutDate) > new Date().setMonth(new Date().getMonth() - 1) ? new Date().setMonth(new Date().getMonth() - 1) : new Date(props.tenant.moveOutDate)) : new Date().setMonth(new Date().getMonth() - 1)}
                        minDate={new Date(dayjs(startDate))}
                        />
                    </Stack>
                </Group>
                <div id='date-popup' className='date-popup'/>
                {/* <Group>
                    <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MMM yy"
                    showMonthYearPicker
                    className='month-selector'
                    customInput={<CustomInput/>}
                    />
                </Group> */}
                <Group position='apart'>
                    <Button className='gray-btn btn-app-text' onClick={()=> props.toggle(true)}>Abbrechen</Button>
                    <Button className='purple-btn btn-app-text' onClick={()=> props.setCustomDates(startDate, endDate)}>Anwenden</Button>
                </Group>
            </Stack>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        tenant: state.generalReducer.tenantData,
    }
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(mapStateToProps, actions)(CustomDateRangePickerModal);
