import { Button, Group, SegmentedControl, Select, Skeleton, Stack, Tabs } from '@mantine/core';
//import { DatePicker } from '@mantine/dates';
import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import DatePickerIcon from '../../../assests/svg/DatePicker.svg';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HeadingContainer from '../../components/CommonComponents/HeadingContainer';
import { IconChevronDown } from '@tabler/icons';
//import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import ConsumptionCard from '../../components/CommonComponents/ConsumptionCard';
import ConsumptionChartCard from '../../components/CommonComponents/ConsumptionChartCard';
import AssessmentCard from '../../components/CommonComponents/AssessmentCard';
import ConsumptionChartCardClassComponent from '../../components/CommonComponents/ConsumptionChartCardClassComponent';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker"
import de from 'date-fns/locale/de';
import "react-datepicker/dist/react-datepicker.css";
import { CurrentBillingPeriodGet, LastBillingPeriodGet, PropertiesGet, RentalUnitGet, TenantGet } from '../../../helpers/fetchApis/Properties';
import TipsCard from '../../components/CommonComponents/TipsCard';
import { EnergyEfficiencyLevelsGet, tenantConsumptionGet } from '../../../helpers/fetchApis/MeterConsumptions';
import {set_skeleton_loader} from '../../../redux/action/General/generalActions';
import EmptyState from '../../components/CommonComponents/EmptyState';
import CustomDateRangePickerModal from '../../components/Modals/CustomDateRangePickerModal';


registerLocale('de', de);
//import {Tabs, Tab, Box} from '@mui/material';
const defaultMonths = [0,1,2,3,4,5,6,7,8,9,10,11]

const CustomInput = React.forwardRef((props, ref) => {
    return (
      <Group noWrap className='month-selector' spacing={5}>
        <img src={DatePickerIcon} width='16px'/>
        <span onClick={props.onClick} ref={ref}>         
          {props.value || props.placeholder}
        </span>
      </Group>
    );
});
class Consumption extends Component {
state={
    properties: [],
    rentalUnits: [],
    tenants: [],
    selectedProperty: null,
    selectedRentalUnit: null,
    selectedTenant: null,
    selectedTab: 0,
    months: defaultMonths.map((month)=> `${dayjs().month([month]).format('MMMM YYYY')}`),
    activeTab: this.props.property.warmWaterConsumptionAllocationKey === null ? 'RoomHeating' : 'HeatingTotal',
    selectedDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    chartDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    tenantConsumption: null,
    monthsSelectorData: [],
    selectedMonthSelector: 'currentMonth',
    consumptionPercentage: '0%',
    consumptionValue: '0',
    consumptionHigh: false,
    savingsPotentialValue: '0',
    savingsPotentialPercentage: '0',
    skeletonLoaderConsumptionCards: false,
    skeletonLoaderConsumptionChart: false,
    skeletonLoaderEnergyEfficiencyLevels: false,
    currentBillingPeriod: null,
    lastBillingPeriod: null,
    energyEfficiencyLevels: {},
    openCustomDateRangeModal: false,
    previousSelectedMonthSelector: 'currentMonth'
}
constructor(props) {
    super(props);
}

componentDidMount() {
    var duration = require('dayjs/plugin/duration')
    var localeData = require('dayjs/plugin/localeData')
    dayjs.locale('de')
    dayjs.extend(localeData)
    dayjs.extend(duration);
    dayjs.duration({ months: 12 });
    this.setState({monthsSelectorData: [
        {
            label: `${dayjs().subtract(1, 'month').endOf('month').format('MMMM YYYY')}`,//'Letzter Monat',
            value: 'currentMonth',
            startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
            label: `${dayjs().subtract(2, 'month').endOf('month').format('MMMM YYYY')}`,//'Letzter Monat',
            value: 'lastMonth',
            startDate: dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
            label: 'Aktuelles Jahr',
            value: 'currentYearTotal',
            startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
            endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        {
            label: 'Letztes Jahr (gesamt)',
            value: 'lastYearTotal',
            startDate: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        },
        {
            label: 'Aktueller Abrechnungszeitraum',
            value: 'currentBillingPeriod',
            startDate: dayjs().endOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
            label: 'Letzter Abrechnungszeitraum',
            value: 'lastBillingPeriod',
            startDate: dayjs().endOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
            label: 'Benutzerdefiniert',
            value: 'customTime',
            startDate: new Date(dayjs().subtract(2, 'month').startOf('month')),
            endDate: new Date(dayjs().subtract(1, 'month').endOf('month')),
        },
    ]});
    (this.props.property.warmWaterConsumptionAllocationKey !== null || this.props.property.heatingConsumptionAllocationKey !== null) && this.getTenantConsumption();
    (this.props.property.warmWaterConsumptionAllocationKey !== null || this.props.property.heatingConsumptionAllocationKey !== null) && this.getEnergyEfficiencyLevels();

    CurrentBillingPeriodGet(this.props.property.id , res=>{
        console.log('CurrentBillingPeriodGet: ', res);
        let {monthsSelectorData} = this.state;
        monthsSelectorData.map((item)=>{
            if(item.value === 'currentBillingPeriod'){
                item.startDate= dayjs(res.startDate).endOf('month').format('YYYY-MM-DD');
                item.endDate= dayjs(res.endDate).endOf('month').format('YYYY-MM-DD');
            }
        });
        this.setState({monthsSelectorData});
        LastBillingPeriodGet(this.props.property.id, res1=>{
            let {monthsSelectorData} = this.state;
            monthsSelectorData.map((item)=>{
                if(item.value === 'lastBillingPeriod'){
                    item.startDate= dayjs(res1.startDate).endOf('month').format('YYYY-MM-DD');
                    item.endDate= dayjs(res1.endDate).endOf('month').format('YYYY-MM-DD');
                }
            });
            this.setState({monthsSelectorData});
        })
    })
}

// componentDidUpdate(prevState){
//     console.log('prevState: ', prevState);
//     if(prevState.activeTab !== this.state.activeTab || prevState.selectedDate !== this.state.selectedDate){
//         this.getTenantConsumption();
//     }
// }

getTenantConsumption=()=>{
    this.setSkeletonLoaderConsumptionCard(true);
    const {activeTab, selectedDate, startDate, endDate} = this.state;
    const {profile} = this.props;
    tenantConsumptionGet(activeTab, startDate, endDate, profile.id, this.setSkeletonLoaderConsumptionCard, res=> {
        console.log('tenantConsumptionGet: ', res);
        this.setState({
            tenantConsumption: res, 
            consumptionPercentage: res && res.consumptionKWH && res.lastYearConsumptionKWH ? `${Math.floor(Math.abs((res.consumptionKWH - res.lastYearConsumptionKWH) / res.consumptionKWH) * 100)}%` : null,
            consumptionValue: res && res.consumptionKWH ? Math.round(res.consumptionKWH) : '-',
            consumptionHigh: res && res.consumptionKWH > res.lastYearConsumptionKWH ? true : false,
            savingsPotentialValue: res && (res.consumptionKWH && res.consumptionAvgHouseholdKWH) && (res.consumptionKWH > res.consumptionAvgHouseholdKWH) ? Math.round(res.consumptionKWH - res.consumptionAvgHouseholdKWH) : '-',
            savingsPotentialPercentage: res && (res.consumptionKWH > res.consumptionAvgHouseholdKWH) && res.savingPotential && res.savingPotential < 0 ? Math.floor(Math.abs(res.savingPotential)) : null
        });
        this.setSkeletonLoaderConsumptionCard(false);
    });
}

getEnergyEfficiencyLevels=()=>{
    this.setSkeletonLoaderEnergyEfficiencyLevels(true);
    const {endDate} = this.state;
    const {profile} = this.props;
    EnergyEfficiencyLevelsGet(endDate, profile.id, this.setSkeletonLoaderEnergyEfficiencyLevels, res=> {
        console.log('EnergyEfficiencyLevelsGet: ', res);
        this.setSkeletonLoaderEnergyEfficiencyLevels(false);
        this.setState({energyEfficiencyLevels: res});
    });
}

setSkeletonLoaderConsumptionCard =(value)=>{
    this.setState({skeletonLoaderConsumptionCards: value});
}

setSkeletonLoaderConsumptionChart =(value)=>{
    this.setState({skeletonLoaderConsumptionChart: value});
}

setSkeletonLoaderEnergyEfficiencyLevels =(value)=>{
    this.setState({skeletonLoaderEnergyEfficiencyLevels: value});
}

handleChange = (event, newValue) => {
    this.setState({selectedTab: newValue});
};

setActiveTabHandler= (value)=>{
    this.setState({activeTab: value}, ()=> { this.getTenantConsumption(); });
}

renderConsumptionCards=(skeletonLoader)=>{
    const {isTablet, isMobile} = this.props;
    const {tenantConsumption, consumptionHigh, consumptionPercentage, consumptionValue, savingsPotentialPercentage, savingsPotentialValue} = this.state;
    console.log('tenantConsumption: ', tenantConsumption);
    return (
        isMobile ?
        <Stack spacing={'xs'} align='flex-start' style={{justifyContent: 'center'}}>
            <Group spacing={'xs'} style={{width: '100%'}} noWrap>
                {/* <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Kosten'}
                    value={'896'}
                    unit={'Euro'}
                    percentage={'15%'}
                    highConsumption={true}
                    isMobile={isMobile}
                    />
                </Skeleton> */}
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Verbrauch'}
                    value={consumptionValue}
                    unit={'kWh'}
                    percentage={consumptionPercentage}
                    highConsumption={consumptionHigh}
                    isMobile={isMobile}
                    />
                </Skeleton>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Einsparpotential'}
                    value={savingsPotentialValue}
                    unit={'kWh'}
                    percentage={savingsPotentialPercentage}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </Group>
            {/* <Group spacing={'xs'} grow>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'CO2-Emission'}
                    value={'2500'}
                    unit={'kWh'}
                    percentage={'15%'}
                    highConsumption={true}
                    isMobile={isMobile}
                    />
                </Skeleton>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Einsparpotential'}
                    value={'90'}
                    unit={'Euro'}
                    percentage={85}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </Group> */}
        </Stack>
        :
        <Group spacing={isTablet ? 'xs' : 'xl'} align='flex-start' style={{justifyContent: isMobile && 'center'}}>
            {/*<div>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Kosten'}
                    value={'896'}
                    unit={'Euro'}
                    percentage={'15%'}
                    highConsumption={true}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </div>*/}
            <div>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Verbrauch'}
                    value={consumptionValue}
                    unit={'kWh'}
                    percentage={consumptionPercentage}
                    highConsumption={consumptionHigh}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </div>
            {/*<div>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'CO2-Emission'}
                    value={'2500'}
                    unit={'kWh'}
                    percentage={'15%'}
                    highConsumption={true}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </div>*/}
            <div>
                <Skeleton visible={skeletonLoader} className='skeleton-box-shadow'>
                    <ConsumptionCard 
                    heading={'Einsparpotential'}
                    value={savingsPotentialValue}
                    unit={'kWh'}
                    percentage={savingsPotentialPercentage}
                    isMobile={isMobile}
                    />
                </Skeleton>
            </div>
        </Group>
    )
}

handleSelectItemChange =(value, others)=>{
    console.log('handleSelectItemChange: ', value);
    this.setState({selectedMonthSelector: value, startDate: others.startDate, endDate: others.endDate}, ()=> { this.getTenantConsumption(); this.getEnergyEfficiencyLevels(); });
}

handleCustomSelectItemChange =(value)=>{
    console.log('handleCustomSelectItemChange: ', value);
    this.setState({previousSelectedMonthSelector: this.state.selectedMonthSelector, selectedMonthSelector: value, openCustomDateRangeModal: true});
}

SelectItem = forwardRef(
    ({ image, label, description, value, ...others }, ref) => (
      <div ref={ref} {...others} 
        onClick={(e)=> {
            e.preventDefault();
            label === 'Benutzerdefiniert' && this.handleCustomSelectItemChange(value);
        }} 
        className={`time-period-select-item ${others['data-selected'] && 'selected-item'}`}>
        {console.log('SelectItem: ', ref)}
        {
        // label === 'Benutzerdefiniert' && others['data-selected'] ? 
        // <Stack spacing={'xs'}>
        //     <Group>
        //         {'Benutzerdefinierter Zeitraum'}
        //     </Group>
        //     <Stack spacing={15}>
        //         <Group noWrap spacing={7} position='apart'>
        //             <DatePicker
        //             portalId="respond-dashboard-page"
        //             locale="de"
        //             selected={others.startDate}
        //             onChange={(date) => {
        //                 let {monthsSelectorData} = this.state;
        //                 monthsSelectorData.map((item)=> {
        //                     if(item.label === 'Benutzerdefiniert'){
        //                         item.startDate= date;
        //                         return;
        //                     }
        //                 })
        //                 this.setState({monthsSelectorData, //startDate: dayjs(date).endOf('month').format('YYYY-MM-DD'), endDate: dayjs(others.endDate).endOf('month').format('YYYY-MM-DD')
        //                 });
        //             }}
        //             dateFormat="MMM yy"
        //             customInput={<CustomInput/>}
        //             showMonthYearPicker
        //             className='month-selector'
        //             maxDate={new Date().setMonth(new Date().getMonth() - 1)}
        //             />
        //             <span>bis</span>
        //             <DatePicker
        //             portalId="respond-dashboard-page"
        //             locale="de"
        //             selected={others.endDate}
        //             onChange={(date) => {
        //                 let {monthsSelectorData} = this.state;
        //                 monthsSelectorData.map((item)=> {
        //                     if(item.label === 'Benutzerdefiniert'){
        //                         item.endDate= date;
        //                         return;
        //                     }
        //                 })
        //                 this.setState({monthsSelectorData, //startDate: dayjs(others.startDate).endOf('month').format('YYYY-MM-DD'), endDate: dayjs(date).endOf('month').format('YYYY-MM-DD')
        //                 });
        //             }}
        //             dateFormat="MMM yy"
        //             customInput={<CustomInput/>}
        //             showMonthYearPicker
        //             className='month-selector'
        //             maxDate={new Date().setMonth(new Date().getMonth() - 1)}
        //             />
        //         </Group>
        //         <Group position='right'>
        //             <div 
        //             onClick={()=> {
        //                 let customDate= this.state.monthsSelectorData.find((item)=> {
        //                     if(item.label === 'Benutzerdefiniert'){
        //                         return item;
        //                     }
        //                 });
        //                 if(customDate !== undefined){
        //                     this.setState({
        //                         startDate: dayjs(customDate.startDate).endOf('month').format('YYYY-MM-DD'), 
        //                         endDate: dayjs(customDate.endDate).endOf('month').format('YYYY-MM-DD') 
        //                     }, ()=> { this.getTenantConsumption(); this.getEnergyEfficiencyLevels(); });
                            
        //                 }
        //             }} className={'datepicker-purple-btn'}>
        //                 <div className='datepicker-btn-app-text'>Anwenden</div>
        //             </div>
        //         </Group>
        //     </Stack>
        // </Stack>
        // :
        <Group noWrap>
          {label === 'Benutzerdefiniert' ? 'Benutzerdefinierter Zeitraum' : label}
        </Group>
        }
      </div>
    )
  );

render() {
    const {selectedTab, startDate, endDate, months, activeTab, selectedDate, properties, rentalUnits, tenants, selectedProperty, selectedRentalUnit, selectedTenant, chartDate, monthsSelectorData, consumptionPercentage, consumptionHigh, skeletonLoaderConsumptionCards, skeletonLoaderConsumptionChart, skeletonLoaderEnergyEfficiencyLevels, selectedMonthSelector, energyEfficiencyLevels, openCustomDateRangeModal} = this.state; 
    const {isMobile, isTablet, profile, loader, skeletonLoader, property, tenant} = this.props;
    console.log('property: ', property);
    console.log('dayjs: ', dayjs().month([0]).format('MMMM YYYY'));
    console.log('profile: ', profile.id);
    return (
        <>
        <div id="empty-dashboard-page" className="empty-dashboard-page" style={{ display: 'none' }}></div>
        <div className="dashboard" id="respond-dashboard-page" style={{ display: 'block' }}>
            <HeadingContainer
            title={this.props.property.warmWaterConsumptionAllocationKey === null && this.props.property.heatingConsumptionAllocationKey !== null ? 'Ihr Heizungsverbrauch' : 'Ihre Verbräuche'}
            icon={ConsumptionIcon}
            //showProfileImage
            />
            <div className='divider'/>
            <div className='container'>
                {(this.props.property.warmWaterConsumptionAllocationKey !== null || this.props.property.heatingConsumptionAllocationKey !== null) ? 
                <div className='top-content'>
                    {/* Top tab bar and month selector */}
                    {isMobile ?
                    <Stack style={{width: '100%'}}>
                        <Stack align='center' >
                            {property.warmWaterConsumptionAllocationKey !== null &&
                                <div style={{ backgroundColor: 'white', width: '100%'}}>
                                    <Tabs value={activeTab} onTabChange={this.setActiveTabHandler} className={'tabs-heating-warmwater'}>
                                        <Tabs.List>
                                            <Tabs.Tab value="HeatingTotal"><span>Gesamt</span></Tabs.Tab>
                                            <Tabs.Tab value="RoomHeating"><span>Heizung</span></Tabs.Tab>
                                            <Tabs.Tab value="WarmWater"><span>Warmwasser</span></Tabs.Tab>
                                        </Tabs.List>
                                    </Tabs>
                                </div>}
                            <Group grow style={{width: '100%'}}>
                                <Select
                                data={monthsSelectorData}
                                styles={{
                                    input: {minWidth: '100%'},
                                }}
                                //defaultValue={'currentMonth'}
                                value={selectedMonthSelector}
                                itemComponent={this.SelectItem}
                                onChange={(value)=> {
                                    if(value !== 'customTime'){
                                        this.handleSelectItemChange(value, monthsSelectorData.find((item)=> item.value === value));
                                    }else{
                                        value.preventDefault();
                                    }
                                }}
                                />
                            </Group>
                        </Stack>
                        {this.renderConsumptionCards(skeletonLoaderConsumptionCards)}
                        <Stack style={{width: '100%'}} spacing={'xs'}>
                                    <div>
                                        <Skeleton visible={skeletonLoaderConsumptionChart} className='skeleton-box-shadow'>
                                            <ConsumptionChartCard
                                            percentage={consumptionPercentage}
                                            highConsumption={consumptionHigh}
                                            consumptionDate={selectedDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedMonthSelector={selectedMonthSelector}
                                            selectedDate={chartDate}
                                            consumptionType={activeTab}
                                            selectedTenant={profile.id}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            setLoader={(value)=> this.setSkeletonLoaderConsumptionChart(value)}/>
                                        </Skeleton>
                                    </div>
                                </Stack>
                    </Stack>
                    :
                    <Stack className='top-tab-bar' spacing={isTablet ? 0 : 'xl'}>
                        <Group spacing={isTablet ? 'xs' : 'xl'} noWrap align={'flex-start'}>
                            <Stack spacing={isTablet ? 'xs' : 'xl'}>
                                <Group spacing={isTablet ? 'xs' : 'xl'} align={'flex-start'}>
                                {property.warmWaterConsumptionAllocationKey !== null && 
                                    <div>
                                        <Tabs value={activeTab} onTabChange={this.setActiveTabHandler} className={'tabs-heating-warmwater'}>
                                            <Tabs.List>
                                                <Tabs.Tab value="HeatingTotal"><span>Gesamt</span></Tabs.Tab>
                                                <Tabs.Tab value="RoomHeating"><span>Heizung</span></Tabs.Tab>
                                                <Tabs.Tab value="WarmWater"><span>Warmwasser</span></Tabs.Tab>
                                            </Tabs.List>
                                        </Tabs>
                                    </div>}
                                    <div>
                                        <Select
                                        data={monthsSelectorData}
                                        styles={{
                                            input: {minWidth: '230px'}
                                        }}
                                        value={selectedMonthSelector}
                                        itemComponent={this.SelectItem}
                                        onChange={(value)=> {
                                            if(value !== 'customTime'){
                                                this.handleSelectItemChange(value, monthsSelectorData.find((item)=> item.value === value));
                                            }else{
                                                value.preventDefault();
                                            }
                                        }}
                                        />
                                    </div>
                                </Group>
                                {isTablet && this.renderConsumptionCards(skeletonLoaderConsumptionCards)}
                            </Stack>
                            {/* {isTablet &&
                            <TipsCard isMobile={isMobile} isTablet={isTablet}/>} */}
                        </Group>
                        {/* Content */}
                        <Stack spacing={isTablet ? 'xs' : 'xl'}>
                            <Group noWrap align={'flex-start'} spacing={isTablet ? 'xs' : 'xl'}>
                                {!isTablet && this.renderConsumptionCards(skeletonLoaderConsumptionCards)}
                            </Group>
                            
                            {!isMobile &&
                            <>
                            <Group align={'flex-start'} spacing={'xl'}>
                                <Stack style={{width: isTablet && '100%'}} spacing={isTablet ? 'xs' : 'xl'}>
                                    <div>
                                        <Skeleton visible={skeletonLoaderConsumptionChart} className='skeleton-box-shadow'>
                                            <ConsumptionChartCard
                                            percentage={consumptionPercentage}
                                            highConsumption={consumptionHigh}
                                            consumptionDate={selectedDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedMonthSelector={selectedMonthSelector}
                                            selectedDate={chartDate}
                                            consumptionType={activeTab}
                                            selectedTenant={profile.id}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            setLoader={(value)=> this.setSkeletonLoaderConsumptionChart(value)}/>
                                        </Skeleton>
                                    </div>
                                    <div>
                                        <Skeleton visible={skeletonLoaderEnergyEfficiencyLevels} className='skeleton-box-shadow'>
                                            <AssessmentCard
                                            endDate={endDate}
                                            energyEfficiencyLevels={energyEfficiencyLevels}
                                            isMobile={isMobile}
                                            isTablet={isTablet}/>
                                        </Skeleton>
                                    </div>
                                </Stack>
                                {!isMobile && <TipsCard/>}
                            </Group>
                            </>}
                        </Stack>
                    </Stack>}
                </div>
                :
                <Stack style={{height: '100vh'}}>
                    <EmptyState
                    icon={ConsumptionIcon}
                    title='Verbräuche sind noch nicht verfügbar.'
                    subText='Bitten Sie Ihren Vermieter die Einrichtung abzuschließen.'
                    />
                </Stack>}
            </div>
            {console.log('monthsSelectorData: ', monthsSelectorData[6]?.startDate)}
            {<CustomDateRangePickerModal
            open={openCustomDateRangeModal}
            toggle={(cancel=false)=> this.setState({openCustomDateRangeModal: false, selectedMonthSelector: cancel ? this.state.previousSelectedMonthSelector : this.state.selectedMonthSelector})}
            startDate={monthsSelectorData[6]?.startDate}
            endDate={monthsSelectorData[6]?.endDate}
            setCustomDates={(startDate, endDate)=> { 
                let {monthsSelectorData} = this.state;
                monthsSelectorData.map((item)=> {
                    if(item.label === 'Benutzerdefiniert'){
                        item.startDate= startDate;
                        item.endDate= endDate;
                        return;
                    }
                })
                this.setState({monthsSelectorData}, ()=>{
                    let customDate= this.state.monthsSelectorData.find((item)=> {
                        if(item.label === 'Benutzerdefiniert'){
                            return item;
                        }
                    });
                    if(customDate !== undefined){
                        this.setState({
                            startDate: customDate.startDate <= new Date(tenant.moveInDate) ? dayjs(tenant.moveInDate).format('YYYY-MM-DD') : dayjs(customDate.startDate).startOf('month').format('YYYY-MM-DD'), 
                            endDate: tenant.moveOutDate !== null ? (customDate.endDate >= new Date(tenant.moveOutDate) ? dayjs(tenant.moveOutDate).format('YYYY-MM-DD') : dayjs(customDate.endDate).endOf('month').format('YYYY-MM-DD')) : dayjs(customDate.endDate).endOf('month').format('YYYY-MM-DD'),
                            openCustomDateRangeModal: false
                        }, ()=> { this.getTenantConsumption(); this.getEnergyEfficiencyLevels(); });
                        
                    }
                });
            }}
            />}
        </div>
        </>
    );
}
}
const mapStateToProps = (state) => {
    return {
        profile: state.generalReducer.profileData,
        isMobile: state.generalReducer.isMobile,
        isTablet: state.generalReducer.isTablet,
        account: state.generalReducer.accountData,
        loader: state.generalReducer.loader,
        skeletonLoader: state.generalReducer.skeletonLoader,
        property: state.generalReducer.propertyData,
        tenant: state.generalReducer.tenantData,
    };
};

const actions={
    set_skeleton_loader
}

export default connect(mapStateToProps, actions)(Consumption);
