import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Modal } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import RedCross from '../../../assests/svg/RedCross.svg';
import { EmailVerificationInstructionsPost, VerifyEmailPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';


const EmailVerificationModal= (props) => {
    //const [opened, setOpened] = useState(props.open);
    const [passcode, setPasscode] = useState('');

    const sendNewPassCode=()=>{
        EmailVerificationInstructionsPost({email: props.email}, props.set_loader, res=> {
            showNotification({
                message: 'Ein neuer Zugangscode wurde zugesendet. Bitte prüfen Sie Ihren Email-Posteingang.',
                icon: <img src={GreenTick} alt='green-tick'/>,
            })
            props.set_loader(false);
        })
    }
    
    const verifyEmail=(e)=>{
        e.preventDefault();
        VerifyEmailPost({email: props.email, passwordResetCode: passcode}, props.set_loader, res=> {
            if(res === true){
                showNotification({
                    message: 'Ihre E-Mail wird überprüft.',
                    icon: <img src={GreenTick} alt='green-tick'/>
                });
                props.toggle();
            }else{
                showNotification({
                    message: 'Der Zugangscode ist nicht korrekt oder abgelaufen. Bitte probieren Sie es erneut oder fordern Sie einen neuen Zugangscode an.',
                    icon: <img src={RedCross} alt='red-tick'/>
                });
            }
        })
    }

    return (
    <Modal
    opened={props.open}
    centered
    overlayOpacity={0.2}
    onClose={() => props.toggle()}
    title="Bestätigen Sie die Änderung">
            <Stack style={{width: '100%'}}>
                <Group><span className='card-sub-text'>Die Email-Adresse dient Ihnen zur Anmeldung beim Mieterprotal.</span></Group>
                <Group><span className='card-sub-text'>Geben Sie zur Bestätigung der Änderung bitte den Zugangscode ein, den wir soeben an diese Email-Adresse gesendet haben. Prüfen Sie ggf. auch den Spam-Ordner.</span></Group>
                <Group grow>
                    <form onSubmit={verifyEmail}>
                        <Stack>
                            <Group grow>
                                <TextInput
                                    label="Zugangscode"
                                    value={passcode}
                                    onChange={(e)=> setPasscode(e.target.value)}
                                />
                            </Group>
                            <Group><span className='card-sub-text'>Klicken Sie <span style={{color: '#4949f5', cursor: 'pointer', fontStyle: 'italic'}} onClick={()=> sendNewPassCode()}>hier</span> um, den Zugangscode erneut zuzusenden.</span></Group>
                            <Group style={{justifyContent: 'space-between'}}>
                                <Button className='gray-btn btn-app-text' onClick={()=> props.toggle()}>Abbrechen</Button>
                                <Button className='purple-btn btn-app-text' type="submit">Bestätigen</Button>
                            </Group>
                        </Stack>
                    </form>
                </Group>
            </Stack>
        </Modal>
    )
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(null, actions)(EmailVerificationModal);
